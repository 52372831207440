import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';

const ContactInfo = () => {
    const [contactForm, setContactForm] = useState({ userName: '', email: '', message: '' });
    const [feedback, setFeedback] = useState('');

    const handleContactSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "issues"), {
                name: contactForm.userName,
                email: contactForm.email,
                message: contactForm.message
            });
            console.log("Document written with ID: ", docRef.id);
            console.log('Contact form submitted:', contactForm);

            // Show alert to user
            alert("Message sent!");

            // Reset form after submission
            setContactForm({ userName: '', email: '', message: '' });
        } catch (e) {
            console.error("Error adding document: ", e);
            alert("Error sending message. Please try again.");
        }
    };


    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "feedback"), {
                feedback: feedback
            })
            alert("Feedback sent")
        } catch (error) {
            console.error("Error adding document: ", e);
            alert("Error sending message. Please try again.");
        }
        console.log('Feedback submitted:', feedback);
        // Reset feedback after submission
        setFeedback('');
    };

    return (
        <div className="support-page">
            <section className="contact-info">
                <h2>Contact Information</h2>
                <p>Email: gigfort.nz@gmail.com</p>
                <p>Response Time: Within 1-3 days</p>
            </section>

            <section className="contact-form">
                <h2>Contact Form</h2>
                <form onSubmit={handleContactSubmit}>
                    <input
                        type="text"
                        placeholder="Name"
                        value={contactForm.name}
                        onChange={(e) => setContactForm({ ...contactForm, userName: e.target.value })}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={contactForm.email}
                        onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })}
                        required
                    />
                    <textarea
                        placeholder="Message"
                        value={contactForm.message}
                        onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })}
                        required
                    ></textarea>
                    <button type="submit">Send</button>
                </form>
            </section>

            <section className="app-info">
                <h2>App Information</h2>
                <p>
                    Discover Wellington's vibrant music scene with Gig Fort, the ultimate gig guide app for Aotearoa's cultural capital. Never miss a beat with our comprehensive, centralized platform designed to make finding live music effortless.
                </p>
                <p>Our intuitive map feature displays daily gigs, allowing you to explore events across the city at a glance. Customize your search by date, distance, start time, and genre to find the perfect show. Prefer a list view? Browse gigs by day or week with our sleek, user-friendly interface.</p>
                <p>Create an account to unlock social features inspired by your favorite apps. Save gigs, set reminders, and like events to curate your personal music calendar. Whether you're a local or a visitor, Gig Fort ensures you're always in tune with Wellington's diverse musical offerings.</p>
                <p>Say goodbye to scattered information and clunky interfaces. With Gig Fort, Wellington's dynamic music scene is just a tap away. Download now and let the music guide you!</p>
                <p>Version: 1.0.6</p>
            </section>

            <section className="privacy-policy">
                <h2>Privacy Policy</h2>
                <p>Our privacy policy can be found <a href = "https://gigfort.nz/privacypolicy">here</a></p>
            </section>

            <section className="social-media">
                <h2>Social Media</h2>
                <p>Follow us on Instagram: <a href="https://www.instagram.com/gig.fort/" target="_blank" rel="noopener noreferrer">@gig.fort</a></p>
            </section>

            <section className="feedback">
                <h2>Feedback</h2>
                <form onSubmit={handleFeedbackSubmit}>
                    <textarea
                        placeholder="Your feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        required
                    ></textarea>
                    <button type="submit">Submit Feedback</button>
                </form>
            </section>
        </div>
    )
}

export default ContactInfo